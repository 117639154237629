<template>
  <div class="panel rm-panel">
    <div :class="panelHeaderClass">
      <div class="panel-title rm-panel-title" v-if="titleIsHtml" v-html="title"/>
      <div class="d-flex panel-title rm-panel-title justify-content-between valign-middle" v-else-if="!textCenter">
        {{ title }}
      </div>
      <div class="d-flex panel-title rm-panel-title justify-content-center valign-middle" v-else>
        {{ title }}
      </div>
    </div>
    <div class="panel-body">
      <slot name="panelBody"/>
    </div>
  </div>
</template>

<script>

export default {
  name: "RMPanel",
  props: {
    title: {
      required: true
    },
    titleIsHtml: {
      type: Boolean,
      default: false
    },
    textCenter: {
      type: Boolean,
      default: false
    },
    headerClass: {
      type: String,
      default: "rm-panel-heading"
    }
  },
  computed: {
    panelHeaderClass() {
      return `panel-heading ${this.headerClass}`;
    }
  }
}
</script>