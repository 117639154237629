<template>
  <div class="row" v-if="!isLoggedInUser">
    <div class="col-12">
      <div class="alert alert-primary">
        {{ $t("common.wantMoreThanDaily") }}
        <strong>
          <router-link to="/login">{{ $t("common.logIn.official") }}</router-link>
        </strong>
        {{ $t("common.andPlayMore") }}
      </div>
    </div>
  </div>
</template>

<script>

import {isUserLoggedIn} from "@/_helpers";

export default {
  name: "RMNotLoggedInBanner",
  computed: {
    isLoggedInUser() {
      return isUserLoggedIn();
    },
  }
}

</script>